import { lazy } from 'react';
const SubAdmin = lazy(() => import('../container/SubAdmin/SubAdmin'));
const AddEditSubAdmin = lazy(() => import('../container/SubAdmin/AddEditSubAdmin'));
const Networks = lazy(() => import('../container/Networks/Networks'));
const users = lazy(() => import('../container/WhiteLabel/admin/users'));
const ViewEditUser = lazy(() => import('../container/Users/ViewEditUser'));
const error = lazy(() => import('../container/WhiteLabel/admin/Error'));
const ZitadelAuth = lazy(() => import('../container/WhiteLabel/admin/index'));
const Customize = lazy(() => import('../container/WhiteLabel/admin/customize'));
const AddEditNetwork = lazy(() => import('../container/Networks/AddEditNetwork'));
const Offers = lazy(() => import('../container/Offers/Offers'));
const ViewOffer = lazy(() => import('../container/Offers/ViewOffer'));
const Requests = lazy(() => import('../container/Requests/Requests'));
const ViewRequest = lazy(() => import('../container/Requests/ViewRequest'));
const Threads = lazy(() => import('../container/Threads/Threads'));
const ViewThread = lazy(() => import('../container/Threads/ViewThread'));
const Dashboard = lazy(() => import('../container/Dashboard'));
const EmailTemplate = lazy(() => import('../container/template/emailTemplate'));
const ChangePassword = lazy(() => import('../container/ChangePassword'));
const ApplicationFormFullDetail = lazy(() => import('../container/Networks/NetworkTabs/ApplicationFormFullDetail'));

const basePath = '/whitelabel/admin';

const adminRoute = [
  {
    path: `${basePath}/users`,
    component: users,
    private: true,
    exact: true,
    name: 'Users',
  },
  {
    path: `${basePath}/users/view`,
    exact: true,
    name: 'ViewEditUser',
    component: ViewEditUser,
    private: true,
    onlyAdmin: false,
  },
  {
    path: `${basePath}/Customize`,
    component: Customize,
    private: true,
    exact: true,
    name: 'CustomizeTheme',
  },
  {
    path: `${basePath}/login`,
    component: ZitadelAuth,
    private: false,
    exact: true,
    name: 'WhiteLabelAdminLogin',
  },
  {
    path: `${basePath}/admins`,
    component: SubAdmin,
    private: true,
    exact: true,
    name: 'SubAdmin',
    onlyAdmin: true,
  },
  {
    path: `${basePath}/admins/add-edit`,
    exact: true,
    name: 'AddEditSubAdmin',
    component: AddEditSubAdmin,
    private: true,
    onlyAdmin: true,
  },
  {
    path: `${basePath}/error`,
    component: error,
    private: false,
    exact: true,
    name: 'Bad',
  },
  {
    path: `${basePath}/login/success`,
    component: ZitadelAuth,
    private: false,
    exact: true,
    name: 'Success',
  },
  {
    path: `${basePath}/networks`,
    component: Networks,
    private: true,
    exact: true,
    name: 'OrgNetwork',
  },
  {
    path: `${basePath}/networks/add-edit`,
    component: AddEditNetwork,
    private: true,
    exact: true,
    name: 'OrgNetwork',
  },
  {
    path: `${basePath}/networks/view`,
    component: AddEditNetwork,
    private: true,
    exact: true,
    name: 'OrgNetwork',
  },
  {
    path: `${basePath}/networks/view/application-form-detail`,
    component: ApplicationFormFullDetail,
    private: true,
    exact: true,
    name: 'OrgNetwork',
    onlyAdmin: false,
  },
  {
    path: `${basePath}/offers`,
    exact: true,
    name: 'Offers',
    component: Offers,
    private: true,
    onlyAdmin: false,
  },
  {
    path: `${basePath}/offers/view`,
    exact: true,
    name: 'ViewOffer',
    component: ViewOffer,
    private: true,
    onlyAdmin: false,
  },
  {
    path: `${basePath}/requests`,
    exact: true,
    name: 'Requests',
    component: Requests,
    private: true,
    onlyAdmin: false,
  },
  {
    path: `${basePath}/requests/view`,
    exact: true,
    name: 'ViewRequests',
    component: ViewRequest,
    private: true,
    onlyAdmin: false,
  },
  {
    path: `${basePath}/threads`,
    exact: true,
    name: 'Threads',
    component: Threads,
    private: true,
    onlyAdmin: false,
  },
  {
    path: `${basePath}/threads/view`,
    exact: true,
    name: 'Threads',
    component: ViewThread,
    private: true,
    onlyAdmin: false,
  },
  {
    path: `${basePath}/dashboard`,
    exact: true,
    name: 'Dashboard',
    component: Dashboard,
    private: true,
    onlyAdmin: false,
  },
  {
    path: `${basePath}/template`,
    exact: true,
    name: 'Email Template',
    component: EmailTemplate,
    private: true,
    onlyAdmin: false,
  },
  {
    path: `${basePath}/change-password`,
    exact: true,
    name: 'ChangePassword',
    component: ChangePassword,
    private: true,
    onlyAdmin: false,
  },
  {
    path: `${basePath}/*`,
    component: error,
    private: false,
    exact: true,
    name: 'Not Found',
  },
];
export default adminRoute;
